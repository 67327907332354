<script setup>
import {ref, onMounted, watchEffect} from 'vue'
import { useAppStore } from '@store/appStore';
import useExchangeStore from '@store/exchangeStore'
import {  handleCopy } from '@/utils/tools';
import { useRouter} from 'vue-router'
import dayjs from 'dayjs';
import i18n from '@/utils/i18n';

const $t = i18n.global.t;

const appStore = useAppStore();
const exchangeStore = useExchangeStore()
const Router = useRouter()

watchEffect(async () => {
  await init();
});

onMounted(() => {
  handleCopy();
});

/**
 * 时间显示未UTC00:00
 */
function getUtcTime(date) {
  // var utc = require('dayjs/plugin/utc');
  // dayjs.extend(utc);
  // return dayjs.utc(date * 1000).format('YYYY-MM-DD HH:mm:ss');
  return dayjs(date * 1000).format('YYYY-MM-DD HH:mm:ss');
}

/**
 * hash 显示
 */
function makeHash(hash) {
  return `${hash.slice(0,6)}...${hash.slice(hash.length-4,hash.length)}`
}
/**
 * 分页查询
 */

 function changePage(page) {
  exchangeStore.fetchRecords(exchangeStore.page)
  exchangeStore.setPage(page)
 }

console.log('exchange', exchangeStore.records)
function init() {
 exchangeStore.fetchRecords(exchangeStore.page)
}

</script>
<template>
<div class="exchange-record-wrap">
<div class="exchange-record-container">
 
 <!-- 兑换列表 -->
 <div class="pc-list" v-if="appStore.curDevice !== 'phone'">
   <header>{{$t('exchangeRecord.1')}}</header>
  <div class="list-header">
    <div class="cell-header">{{$t('exchangeRecord.2')}}</div>
    <div class="cell-header">{{$t('exchangeRecord.3')}}</div>
    <div class="cell-header">{{$t('exchangeRecord.4')}}</div>
    <div class="cell-header">{{$t('exchangeRecord.5')}}</div>
  </div>
  <div class="list-container">
    <div class="list-row"  v-for="item in exchangeStore.records" :key="item.id">
      <div class="cell-item">
        {{makeHash(item.tx_hash)}} <i :data-clipboard-text="item.tx_hash" class="iconfont icon-cpy cpy-btn"></i>
      </div>
       <div class="cell-item">
        {{item.from_amount}}
      </div>
      <div class="cell-item">
        {{item.to_amount}}
      </div>
       <div class="cell-item">
      {{getUtcTime(item.tx_time)}}
      </div>
    </div>
  </div>
 </div>

  <div class="mobile" v-if="appStore.curDevice === 'phone'">
    <div class="section-header">
      <div class="back-btn" @click="Router.back()"></div>
      <p class="section-title">{{$t('exchangeRecord.1')}}</p>
    </div>
    <div class="record-item" v-for="item in exchangeStore.records" :key="item.id">
      <div class="sub-item">{{$t('exchangeRecord.2')}}： {{makeHash(item.tx_hash)}} <i :data-clipboard-text="item.tx_hash" class="iconfont icon-cpy cpy-btn"></i></div>
      <div class="sub-item">{{$t('exchangeRecord.3')}}：{{item.from_amount}}</div>
      <div class="sub-item">{{$t('exchangeRecord.4')}}：{{item.to_amount}} </div>
      <div class="sub-item">{{$t('exchangeRecord.5')}}：{{getUtcTime(item.tx_time)}}</div>
    </div>
  </div>

  <van-empty
       v-if="!exchangeStore.records.length"
        class="empty"
        :image="'https://game.legendranch.app/legendaryranch/empty-image-search.png'"
        :description="$t('panel.34')"
      />
    <!-- 分页器 -->
      <div class="pagtination-wrap">
        <el-pagination
        v-if="exchangeStore.records.length"
        v-model:current-page="exchangeStore.page"
          background
          layout="prev, pager, next"
          @current-change="changePage"
          :pager-count="5"
          :page-size="10"
          :total="exchangeStore.total"
        ></el-pagination>
      </div>
</div>
</div>
</template>

<style lang="scss" scoped>
.exchange-record-wrap{
  position: relative;
  color: #FFFFFF;
  width: 100%;
  min-height: 100vh;
  font-family: PingFang SC, PingFang SC;
  &::before,&::after {
    content: "";
    position: absolute;
    width: 101%;
    height: 101%;
    left: -1%;
    top: -1%;
  }
  &::before {
    opacity: 1;
    z-index: -1;
    background-color: rgba(0, 0, 0, .7);
  }
  &::after {
    background-image: url('https://game.legendranch.app/legendaryranch/slideShow/bg-home.webp');
    background-size: cover;
    background-position: 50%;
    z-index: -2;
  }
}
.exchange-record-container {
  color: #FFFFFF;
  font-size: 0.2rem;
  margin: 0 auto;
  padding: 0 0.32rem;
  @media (min-width: $pad) {
      width: 12rem;
     
    }
 header {
  font-size: 0.44rem;
  padding: 0.84rem 0 0.17rem 0;
 }
}

.list-header {
  border-bottom: 1px solid #3F3360;
  @include flexPos(center, center);
  .cell-header {
    flex: 1;
    @include flexPos(center, center);
    padding: 0.28rem 0;
  }
}
.list-row {
  display: flex;
  border-bottom: solid 1px #3F3360;
  .cell-item {
    flex: 1;
    @include flexPos(center, center);
    padding: 0.28rem 0;
    font-size: 0.2rem;
  }
  .cpy-btn {
    margin-left: 0.08rem;
  }
}
.section-header {
  display: flex;
  padding: 0.4rem 0;
}

.back-btn {
  width: 0.44rem;
  height: 0.44rem;
  background-image: url('~@img/exchange/back-btn.png');
  background-size: 100% 100%;
}
.section-title {
  font-weight: bold;
font-size: 0.36rem;
color: #FFFFFF;
text-align: center;
flex: 1;
}

.record-item {
  width: 6.86rem;
border-radius: 0.16rem;
border: 1px solid #25303F;
box-sizing: border-box;
padding: 0.24rem;
margin-bottom: 0.2rem;

 .cpy-btn {
    margin-left: 0.08rem;
  }
}
.sub-item {
  font-size: 0.28rem;
  line-height: 1.6;
  display: flex;
}
.pagtination-wrap {
  padding: 0.8rem 0;
   @include flexPos(center);
}

 .el-pagination.is-background .el-pager li {
    background: #05021B;
    color: white;
    border: solid 1px white;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
    background: white;
    color: #553DAD;
}

// 分页器
.pagtination-wrap {
  @include flexPos(center);
  margin: 0.5rem;
  @include -media($phone, padding-bottom, 1.8rem);
  @include -media($pad, padding-bottom, 1.8rem);
  :deep() {
    // .el-input__inner {
    //   background-color: black;
    //   color: white;
    //   // border: 1px solid #656565;
    // }

    // button {
    //   background-color: transparent !important;
    // }

    // .el-pager {
    //   li {
    //     background-color: transparent !important;
    //   }
    // }

    .el-pagination.is-background .el-pager li {
      background-color: black;
      color: white;
      // @include -font-size(0.4rem, 0.4rem, 0.2rem);
       background: #05021B;
    color: white;
    border: solid 1px white;

      &:not(.disable).active {
        background-color: transparent;
        font-weight: bold;
        opacity: 1;

        background: white;
      color: #553DAD;
      }
    }

    .el-pagination.is-background .btn-next {
    background: #05021B;
    color: white; border: solid 1px white;
    }

    .el-pagination.is-background .btn-prev {
      background: #05021B;
    color: white; border: solid 1px white;

    }

    // .el-icon {
    //   @include -font-size(0.4rem, 0.4rem, 0.2rem);
    //   font-weight: bold !important;
    // }
  }
}

.empty {
  @include flexPos(center);
  flex-direction: column;
  margin: 0 auto;
  img {
    width: vw(300);
    height: vw(300);
  }
}

:deep(.van-empty__image) {
  width: 4.5rem !important;
  height: 4.5rem !important;
  margin: 0 auto;
  // margin-left: 45%;
  @include flexPos(center);
  flex-direction: column;
  img {
    width: 3.5rem;
    height: 3.5rem;
    opacity: 0.8;
  }
}

</style>